<template>
	<el-main class="bg">
		<el-form ref="form" :model="form" status-icon label-width="120px" v-if="is_view()">
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="房号" prop="room_number">
					<el-input id="room_number" v-model="form['room_number']" placeholder="请输入房号"
							  v-if="user_group === '管理员' || (form['i_want_to_make_a_reservation_id'] && $check_field('set','room_number')) || (!form['i_want_to_make_a_reservation_id'] && $check_field('add','room_number'))" :disabled="disabledObj['room_number_isDisabled']"></el-input>
					<div v-else-if="$check_field('get','room_number')">{{form['room_number']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="类型" prop="type">
					<el-input id="type" v-model="form['type']" placeholder="请输入类型"
							  v-if="user_group === '管理员' || (form['i_want_to_make_a_reservation_id'] && $check_field('set','type')) || (!form['i_want_to_make_a_reservation_id'] && $check_field('add','type'))" :disabled="disabledObj['type_isDisabled']"></el-input>
					<div v-else-if="$check_field('get','type')">{{form['type']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="图片    " prop="picture">
					<el-input id="picture" v-model="form['picture']" placeholder="请输入图片    "
							  v-if="user_group === '管理员' || (form['i_want_to_make_a_reservation_id'] && $check_field('set','picture')) || (!form['i_want_to_make_a_reservation_id'] && $check_field('add','picture'))" :disabled="disabledObj['picture_isDisabled']"></el-input>
					<div v-else-if="$check_field('get','picture')">{{form['picture']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="价格" prop="price">
					<el-input id="price" v-model="form['price']" placeholder="请输入价格"
							  v-if="user_group === '管理员' || (form['i_want_to_make_a_reservation_id'] && $check_field('set','price')) || (!form['i_want_to_make_a_reservation_id'] && $check_field('add','price'))" :disabled="disabledObj['price_isDisabled']"></el-input>
					<div v-else-if="$check_field('get','price')">{{form['price']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="使用状态" prop="use_status">
					<el-input id="use_status" v-model="form['use_status']" placeholder="请输入使用状态"
							  v-if="user_group === '管理员' || (form['i_want_to_make_a_reservation_id'] && $check_field('set','use_status')) || (!form['i_want_to_make_a_reservation_id'] && $check_field('add','use_status'))" :disabled="disabledObj['use_status_isDisabled']"></el-input>
					<div v-else-if="$check_field('get','use_status')">{{form['use_status']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="备注" prop="remarks">
					<el-input type="textarea" id="remarks" v-model="form['remarks']" placeholder="请输入备注"
						v-if="user_group === '管理员' || (form['i_want_to_make_a_reservation_id'] && $check_field('set','remarks')) || (!form['i_want_to_make_a_reservation_id'] && $check_field('add','remarks'))" :disabled="disabledObj['remarks_isDisabled']"></el-input>
					<div v-else-if="$check_field('get','remarks')">{{form['remarks']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="用户编号" prop="user_number">
						<div v-if="user_group !== '管理员'">
							{{ get_user_session_user_number(form['user_number']) }}
							<!--<el-input id="business_name" v-model="form['user_number']" placeholder="请输入用户编号"-->
							<!--v-if="user_group === '管理员' || (form['i_want_to_make_a_reservation_id'] && $check_field('set','user_number')) || (!form['i_want_to_make_a_reservation_id'] && $check_field('add','user_number'))" :disabled="disabledObj['user_number_isDisabled']"></el-input>-->
							<!--<div v-else-if="$check_field('get','user_number')">{{form['user_number']}}</div>-->
							<el-select v-if="user_group === '管理员' || (form['i_want_to_make_a_reservation_id'] && $check_field('set','user_number')) || (!form['i_want_to_make_a_reservation_id'] && $check_field('add','user_number'))" id="user_number" v-model="form['user_number']" :disabled="disabledObj['user_number_isDisabled']">
								<el-option v-for="o in list_user_user_number" :key="o['username']" :label="o['nickname'] + '-' + o['username']"
										   :value="o['user_id']">
								</el-option>
							</el-select>
							<el-select v-else-if="$check_field('get','user_number')" id="user_number" v-model="form['user_number']" :disabled="true">
								<el-option v-for="o in list_user_user_number" :key="o['username']" :label="o['nickname'] + '-' + o['username']"
										   :value="o['user_id']">
								</el-option>
							</el-select>
						</div>
						<el-select v-else id="user_number" v-model="form['user_number']" :disabled="disabledObj['user_number_isDisabled']">
							<el-option v-for="o in list_user_user_number" :key="o['username']" :label="o['nickname'] + '-' + o['username']"
									   :value="o['user_id']">
							</el-option>
						</el-select>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item>
					<el-button type="primary" @click="submit()">提交</el-button>
					<el-button @click="cancel()">取消</el-button>
				</el-form-item>
			</el-col>

		</el-form>
	</el-main>
</template>

<script>
	import mixin from "@/mixins/page.js";

	export default {
		mixins: [mixin],
		data() {
			return {
				field: "i_want_to_make_a_reservation_id",
				url_add: "~/api/i_want_to_make_a_reservation/add?",
				url_set: "~/api/i_want_to_make_a_reservation/set?",
				url_get_obj: "~/api/i_want_to_make_a_reservation/get_obj?",
				url_upload: "~/api/i_want_to_make_a_reservation/upload?",

				query: {
					"i_want_to_make_a_reservation_id": 0,
				},

				form: {
					"room_number":'', // 房号
					"type":'', // 类型
					"picture":'', // 图片    
					"price":'', // 价格
					"use_status":'', // 使用状态
					"remarks":'', // 备注
					"user_number": 0, // 用户编号
					"i_want_to_make_a_reservation_id": 0 // ID

				},
				disabledObj:{
					"room_number_isDisabled": false,
					"type_isDisabled": false,
					"picture_isDisabled": false,
					"price_isDisabled": false,
					"use_status_isDisabled": false,
					"remarks_isDisabled": false,
				},

			}
		},
		methods: {

			/**
			 * 获取对象之前
			 * @param {Object} param
			 */
			get_obj_before(param) {
				var form = "";
				// 获取缓存数据附加
				form = $.db.get("form");
				$.push(this.form ,form);
				if(this.form && form){
					Object.keys(this.form).forEach(key => {
					Object.keys(form).forEach(dbKey => {
						if(dbKey === "charging_standard"){
							this.form['charging_rules'] = form[dbKey];
							this.disabledObj['charging_rules_isDisabled'] = true;
						};
						if(key === dbKey){
							this.disabledObj[key+'_isDisabled'] = true;
						}
					})
				})
				}
				return param;
			},

			/**
			 * 获取对象之后
			 * @param {Object} json
			 * @param {Object} func
			 */
			get_obj_after(json, func){

			},

			is_view(){
				var bl = this.user_group == "管理员";

				if(!bl){
					bl = this.$check_action('/i_want_to_make_a_reservation/table','add');
					console.log(bl ? "你有表格添加权限视作有添加权限" : "你没有表格添加权限");
				}
				if(!bl){
					bl = this.$check_action('/i_want_to_make_a_reservation/table','set');
					console.log(bl ? "你有表格添加权限视作有修改权限" : "你没有表格修改权限");
				}
				if(!bl){
					bl = this.$check_action('/i_want_to_make_a_reservation/view','add');
					console.log(bl ? "你有视图添加权限视作有添加权限" : "你没有视图添加权限");
				}
				if(!bl){
					bl = this.$check_action('/i_want_to_make_a_reservation/view','set');
					console.log(bl ? "你有视图修改权限视作有修改权限" : "你没有视图修改权限");
				}
				if(!bl){
					bl = this.$check_action('/i_want_to_make_a_reservation/view','get');
					console.log(bl ? "你有视图查询权限视作有查询权限" : "你没有视图查询权限");
				}

				console.log(bl ? "具有当前页面的查看权，请注意这不代表你有字段的查看权" : "无权查看当前页，请注意即便有字段查询权限没有页面查询权限也不行");

				return bl;
			},

			/**
			 * 上传文件
			 * @param {Object} param
			 */
			uploadimg(param) {
				this.uploadFile(param.file, "avatar");
			}

		},
		created() {
		}
	}
</script>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
